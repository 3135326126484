<template>
	<div>
		<v-container px-1>
			<h1>Total Report</h1>
			<v-divider></v-divider>
            
            <!-- <h1>Bank IQD : {{bank_iqd}}</h1>
            <h1>Bank USD : {{bank_usd}}</h1>
            
            <h4>Bank : total income + total pocket - payment - take out</h4>
            <h4> IQD : {{income_final_iqd}} + {{total_added_money_pocket_iqd}} - {{payment_final_iqd}} - {{take_out_iqd}}= {{bank_iqd.toLocaleString()}}</h4>
            <h4> Dollar : {{income_final_usd}} + {{total_added_money_pocket_usd}} - {{payment_final_usd}} - {{take_out_usd}} = {{bank_usd.toLocaleString()}}</h4> -->
			<h1>
				Incomes
			</h1>
			<h2>Income : IQD {{(income_iqd || 0).toLocaleString()}} | Change: IQD {{(income_change_iqd|| 0).toLocaleString()}} </h2>
			<h2>Total Final Income : {{(income_iqd|| 0).toLocaleString()}} - {{(income_change_iqd|| 0).toLocaleString()}} = IQD {{(income_final_iqd|| 0).toLocaleString()}}</h2>
			<v-divider class="my-3"></v-divider>

			<h2>Income : $ {{(income_usd|| 0).toLocaleString()}} | Change: $ {{(income_change_usd|| 0).toLocaleString()}} </h2>
			<h2>Total Final Income : {{(income_usd|| 0).toLocaleString()}} - {{(income_change_usd|| 0).toLocaleString()}} = $ {{(income_final_usd|| 0).toLocaleString()}}</h2>

			<v-divider class="my-3"></v-divider>
			<h1>
				Payments
			</h1>
			<h2>Payment : IQD {{(payment_iqd|| 0).toLocaleString()}} | Change: IQD {{(payment_change_iqd|| 0).toLocaleString()}} </h2>
			<h2>Total Final Payment : {{(payment_iqd|| 0).toLocaleString()}} - {{(payment_change_iqd|| 0).toLocaleString()}} = IQD {{(payment_final_iqd|| 0).toLocaleString()}}</h2>
			<v-divider class="my-3"></v-divider>

			<h2>Payment : $ {{(payment_usd|| 0).toLocaleString()}} | Change: $ {{(payment_change_usd|| 0).toLocaleString()}} </h2>
			<h2>Total Final Payment : {{(payment_usd|| 0).toLocaleString()}} - {{(payment_change_usd|| 0).toLocaleString()}} = $ {{(payment_final_usd|| 0).toLocaleString()}}</h2>

			<v-divider class="my-3"></v-divider>
            <h2>In case the Income Changes were Paid from money put for Payments: </h2>
            <p>
                Income Changes + Payment = Total Amount Out of Bank
            </p>
            <h3>{{(payment_iqd|| 0).toLocaleString() }}  + {{(income_change_iqd|| 0).toLocaleString()}} = {{(payment_iqd+income_change_iqd|| 0|| 0).toLocaleString()}}</h3>
            
			<v-divider class="my-3"></v-divider>
			<h1>
				Take Outs
			</h1>
			<h2>Take Out : IQD {{(take_out_iqd || 0).toLocaleString()}} </h2>
			<h2>Take Out : $ {{(take_out_usd || 0).toLocaleString()}} </h2>
			<v-divider class="my-3"></v-divider>
			<!-- {{total_income_change_iqd}} -->

			<!-- {{total_income_change_usd}} -->

			<!-- {{total_payment_change_iqd}} -->

			<!-- {{total_payment_change_usd}} -->

			<v-layout column>
				<h2 class="mt-4">
					Total Avalible Money in Balance:
					IQD {{total_addded_money[0].total_added_money_amount}}
				</h2>
				<h2 class="mt-4">
					Total Avalible Money in Balance:
					$ {{total_addded_money[1].total_added_money_amount}}
				</h2>
			</v-layout>
			<v-divider class="py-4"></v-divider>

			<h3>Total Income</h3>

			<v-layout py-2>

				<table class="report-table ">
					<thead>
						<tr>
							<th>Total Amount</th>
							<th>Currency</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in income_total" :key="index">

							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.income_currency }}
								</p>
							</td>
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.income_status }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<!-- <v-divider class="py-4"></v-divider> -->
			<!-- <h3>Total Expense</h3>

			<v-layout py-2>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in expense_total" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.expense_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout> -->
			<v-divider class="py-4"></v-divider>

			<h3>Total Payment</h3>
			<v-layout py-2>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in payment_total" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.payment_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<v-divider class="py-4"></v-divider>

			<h3>Total Added Money to Open Balance</h3>
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Amount</th>
							<th>Source</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in added_money_total" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.added_money_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.added_money_source }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<!-- <v-layout>
				<h1 class="mt-8">
					Total Payments
					: {{total.total.toLocaleString()}}
				</h1>
			</v-layout>
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in total_currency" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.payment_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<table class="report-table">
				<thead>
					<tr>
						<th>Account Name</th>
						<th>Total Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in groups" :key="index">
						<td class="item-wrapper">
							<p style="margin: 3px 0px">
								{{ item.payment_account_name }}
							</p>
						</td>
						<td>
							<p style="margin: 3px 0px">
								{{ item.payment_currency }} {{ item.total.toLocaleString() }}
							</p>
						</td>
					</tr>
				</tbody>
			</table>

			<v-divider class="py-4"></v-divider>

			<v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							
							<th>
								{{$store.getters.language.data.payments.payment_date}}
							</th>
							<th>
								Account
							</th>
							<th>
								Phone
							</th>
							<th>
								{{$store.getters.language.data.report.amount}}
							</th>
							<th>
								{{$store.getters.language.data.report.note}}
							</th>
							<th>
								{{$store.getters.language.data.users.user_name}}
							</th>

						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in rows" :key="index">
							<td>{{index+1}}</td>
							<td>{{ new Date(item.payment_date).toISOString().split('T')[0]}} {{ new Date(item.payment_date).toISOString().split('T')[1].split('.')[0]}}</td>
							<td>{{item.payment_account_name}}</td>
							<td>{{item.payment_account_phone}}</td>
							<td>{{item.payment_currency}} {{item.payment_amount.toLocaleString()}}</td>
							<td>{{item.payment_note}}</td>
							<td>{{item.user_name}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
 -->
		</v-container>
	</div>
</template>
<script>
	import requests from '../../../requests/reports.requests'
	export default {
		data() {
			return {
				rows: [],
				total: {},
				groups: [],
				income_total: [],
				expense_total: [],
				payment_total: [],
				total_currency: [],
				added_money_total: [],
				total_addded_money: [],
				income_total_all: [],
				income_iqd: 0,
				income_usd: 0,
				income_change_iqd: 0,
				income_change_usd: 0,
				income_final_iqd: 0,
				income_final_usd: 0,
				payment_iqd: 0,
				payment_usd: 0,
				payment_change_iqd: 0,
				payment_change_usd: 0,
				payment_final_iqd: 0,
				payment_final_usd: 0,
				take_out_iqd: 0,
				take_out_usd: 0,
				take_out_total_all: [],
				total_added_money_pocket_iqd: 0,
				total_added_money_pocket_usd: 0,
                bank_iqd:0,
                bank_usd:0,

				// income_total_paid_usd: [],
				loading: false,
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				requests.totalReport(query).then(res => {
					this.rows = res.data.rows
					this.income_total = res.data.income_total
					this.expense_total = res.data.expense_total
					this.payment_total = res.data.payment_total
					this.added_money_total = res.data.added_money_total
					this.total_addded_money = res.data.total_addded_money
					this.income_total_all = res.data.income_total_all
					this.income_iqd = res.data.income_total_all[0].total_incomes_iqd
					this.income_usd = res.data.income_total_all[0].total_incomes_usd
					this.income_change_iqd = res.data.income_total_all[0].sum_changes_iqd
					this.income_change_usd = res.data.income_total_all[0].sum_changes_usd
					this.income_final_iqd = res.data.income_total_all[0].total_incomes_with_changes_iqd
					this.income_final_usd = res.data.income_total_all[0].total_incomes_with_changes_usd
					this.payment_iqd = res.data.payment_total_all[0].total_payments_iqd
					this.payment_usd = res.data.payment_total_all[0].total_payments_usd
					this.payment_change_iqd = res.data.payment_total_all[0].sum_changes_iqd_pay
					this.payment_change_usd = res.data.payment_total_all[0].sum_changes_usd_pay
					this.payment_final_iqd = res.data.payment_total_all[0].total_payments_with_changes_iqd
					this.payment_final_usd = res.data.payment_total_all[0].total_payments_with_changes_usd
					this.take_out_iqd = res.data.take_out_total_all[0].total_take_out_iqd
					this.take_out_usd = res.data.take_out_total_all[0].total_take_out_usd
                    this.bank_iqd = res.data.total_added_money_pocket[0].total_bank_iqd
                    this.bank_usd = res.data.total_added_money_pocket[0].total_bank_usd
					this.total_added_money_pocket_iqd = res.data.total_added_money_pocket[0].total_added_money_pocket_iqd
					this.total_added_money_pocket_usd = res.data.total_added_money_pocket[0].total_added_money_pocket_usd
					//this.income_total_paid_usd = res.data.income_total_paid_usd

					// this.total = res.data.total
					// this.total_currency = res.data.total_currency
					// this.groups = res.data.total_group
					this.loading = false
				})
			}
		},
	}
</script>