
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    // change the theme to dark

    theme: {
        themes: {
            // dark: true,
            light: {

                primary: '#626ED4',
            },
            dark: {
                primary: '#626ED4',
            },
        }
    }

    
});  
