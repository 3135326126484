
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<v-layout align-center>
				<h1 class="my-6">Payments :</h1>
				<v-spacer></v-spacer>
				<!-- button to go to payment accounts -->
				<v-btn color="#626ED4" @click="$router.push('/payment_accounts-list')">
					<v-icon>mdi-account-cash-outline</v-icon>
				</v-btn>
			</v-layout>
			<v-divider class="my-8"></v-divider>
			<form @submit.prevent="addPayments" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-autocomplete class="mx-1" clearable :items="payment_accounts" v-model="payments.payment_account_id" dense filled outlined item-text="payment_account_name" item-value="payment_account_id" :return-object="false" :label="$store.getters.language.data.payment_accounts.payment_account_name">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-autocomplete class="mx-1" clearable :items="payment_accounts" v-model="payments.payment_account_id" dense filled outlined item-text="payment_account_phone" item-value="payment_account_id" :return-object="false" :label="$store.getters.language.data.payment_accounts.payment_account_phone">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="payments.payment_amount" type="text" :label="$store.getters.language.data.payments.payment_amount" dense class="mx-1" @input="formatPaymentAmount" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-select class="mx-1" clearable :items="['IQD','USD']" v-model="payments.payment_currency" dense filled outlined item-text="payment_currency" item-value="payment_currency" :return-object="false" :label="$store.getters.language.data.payments.payment_currency">
						</v-select>
					</v-flex>
					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="payments.payment_change_amount" type="text" :label="$store.getters.language.data.payments.payment_change_amount" dense class="mx-1"  filled outlined>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-select class="mx-1" clearable :items="['IQD','USD']" v-model="payments.payment_change_currency" dense filled outlined item-text="payment_change_currency" item-value="payment_change_currency" :return-object="false" :label="$store.getters.language.data.payments.payment_change_currency">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-autocomplete class="mx-1" clearable :items="payment_categories" v-model="payments.payment_category_id" dense filled outlined item-text="payment_category_name" item-value="payment_category_id" :return-object="false" :label="$store.getters.language.data.payment_categories.payment_category_name">
						</v-autocomplete>
					</v-flex>
					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['balance','pocket']" v-model="payments.payment_source" dense filled outlined item-text="payment_source" item-value="payment_source" :return-object="false" :label="$store.getters.language.data.payments.payment_source">
						</v-select>
					</v-flex> -->

					<v-flex xs12 lg4 xl4 md3 sm4>
						<v-text-field v-model="payments.payment_date" type="datetime-local" :label="$store.getters.language.data.payments.payment_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="payments.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-textarea v-model="payments.payment_note" type="text" :label="$store.getters.language.data.payments.payment_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.payments.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field class="my-8" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="payment_id">
								<template v-slot:[`item.payment_amount`]="{ item }">
									<div v-if="item.payment_currency == 'IQD'">
										IQD {{ item.payment_amount.toLocaleString() }}
									</div>
									<div v-if="item.payment_currency == 'USD'">
										$ {{ item.payment_amount.toLocaleString() }}
									</div>
								</template>
                                 <template v-slot:[`item.payment_change_amount`]="{ item }">
                                    <div v-if="item.payment_change_currency == 'IQD'">
                                        IQD {{ (item.payment_change_amount|| 0).toLocaleString()}}
                                    </div>
                                    <div v-if="item.payment_change_currency == 'USD'">
                                        $ {{ item.payment_change_amount.toLocaleString() }}
                                    </div>
                                </template>

								<template v-slot:[`item.payment_date`]="{ item }">
									<div>
										{{ new Date(item.payment_date).toISOString().split('T')[0]}} {{ new Date(item.payment_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.payment_id`]="{ item }">
									<div v-if="user.user_role==1">
										<v-btn icon :to="'/payments-list/'+item.payment_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectPayments(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deletePaymentsList">{{$store.getters.language.data.payments.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.payments.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.payments.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deletePayments(selected_payments.payment_id)">
						{{$store.getters.language.data.payments.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/payments.request.js'
	export default {
		data() {
			return {
				payments: {
					payment_amount: null,
				},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				inputValue: null,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_payments: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.payments.payment_amount,
						align: 'start',
						sortable: true,
						value: 'payment_amount',
					},
                     {
						text: this.$store.getters.language.data.payments.payment_change_amount,
						align: 'start',
						sortable: true,
						value: 'payment_change_amount',
					},
					// {
					// 	text: this.$store.getters.language.data.payments.payment_source,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'payment_source',
					// },
					{
						text: this.$store.getters.language.data.payments.payment_note,
						align: 'start',
						sortable: true,
						value: 'payment_note',
					},
					{
						text: this.$store.getters.language.data.payments.payment_date,
						align: 'start',
						sortable: true,
						value: 'payment_date',
					},
					{
						text: this.$store.getters.language.data.payment_accounts.payment_account_name,
						align: 'start',
						sortable: true,
						value: 'payment_account_name',
					},
					{
						text: this.$store.getters.language.data.payment_accounts.payment_account_phone,
						align: 'start',
						sortable: true,
						value: 'payment_account_phone',
					},
					{
						text: this.$store.getters.language.data.payment_categories.payment_category_name,
						align: 'start',
						sortable: true,
						value: 'payment_category_name',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'payment_id',
					}
				],
			}
		},
		computed: {
			payment_accounts() {
				return this.$store.getters.payment_accounts_list
			},
			payment_categories() {
				return this.$store.getters.payment_categories_list
			},
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
			
		},
		mounted() {
			this.readPayments();
			this.payments.user_id = this.user.user_id
		},
		methods: {
			addPayments() {
				this.loading_btn = true
				// this.payments.payment_date = new Date().toISOString().slice(0, 19).replace('T', ' ');
                // i want the payment_amount to be only numbers without formating
                this.payments.payment_amount = this.payments.payment_amount.replace(/,/g, '');
				this.payments.payment_source = 'balance'
				requests.createPayments(this.payments).then(r => {
					if (r.status == 200) {
						this.payments = {
							user_id: this.user.user_id,
							payment_source: 'balance'
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Payments Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Payments',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deletePayments(payment_id) {
				requests.deletePayments(payment_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.payment_id != payment_id
						})
						this.snackbar = {
							value: true,
							text: 'Payments Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deletePaymentsList() {
				let ids = this.selected_rows.map(m => m.payment_id)
				requests.deletePaymentsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.payment_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Payments Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readPayments() {
				this.loading = true
				requests.getAllPayments().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Payments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Payments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectPayments(i) {
				this.selected_payments = i
				this.delete_dialog = true
			},
			formatPaymentAmount() {
				// Remove any non-numeric characters
				let inputValue = this.payments.payment_amount.replace(/[^\d.]/g, '');

				// Split the input value into integer and decimal parts
				let parts = inputValue.split('.');
				let integerPart = parts[0] || '0';
				let decimalPart = parts[1] || '';

				// Add commas to the integer part
				integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

				// Combine integer and decimal parts back
				let formattedValue = integerPart + (decimalPart ? '.' + decimalPart : '');

				// Update the payments.payment_amount with the formatted value
				this.payments.payment_amount = formattedValue;
			},
		},
	}
</script>
                    