
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="my-6">Incomes :</h1>

			<form @submit.prevent="addIncomes" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-autocomplete class="mx-1" clearable :items="income_accounts" v-model="incomes.income_account_id" dense filled outlined item-text="income_account_name" item-value="income_account_id" :return-object="false" :label="$store.getters.language.data.income_accounts.income_account_name">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-autocomplete class="mx-1" clearable :items="income_accounts" v-model="incomes.income_account_id" dense filled outlined item-text="income_account_phone" item-value="income_account_id" :return-object="false" :label="$store.getters.language.data.income_accounts.income_account_phone">
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="incomes.income_detail" type="text" :label="$store.getters.language.data.incomes.income_detail" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="incomes.income_amount" type="text" :label="$store.getters.language.data.incomes.income_amount" dense class="mx-1" @input="formatIncomeAmount" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-select class="mx-1" clearable :items="['IQD','USD']" v-model="incomes.income_currency" dense filled outlined item-text="income_currency" item-value="income_currency" :return-object="false" :label="$store.getters.language.data.incomes.income_currency">
						</v-select>
					</v-flex>
                    <v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="incomes.income_change_amount" type="text" :label="$store.getters.language.data.incomes.income_change_amount" dense class="mx-1"  filled outlined>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-select class="mx-1" clearable :items="['IQD','USD']" v-model="incomes.income_change_currency" dense filled outlined item-text="income_change_currency" item-value="income_change_currency" :return-object="false" :label="$store.getters.language.data.incomes.income_change_currency">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['inshop','storage']" v-model="incomes.income_type" dense filled outlined item-text="income_type" item-value="income_type" :return-object="false" :label="$store.getters.language.data.incomes.income_type">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['paid','unpaid']" v-model="incomes.income_status" dense filled outlined item-text="income_status" item-value="income_status" :return-object="false" :label="$store.getters.language.data.incomes.income_status">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['hyperpost','postponed','direct','fib','fastpay','zaincash','asiahawala']" v-model="incomes.income_payment" dense filled outlined item-text="income_payment" item-value="income_payment" :return-object="false" :label="$store.getters.language.data.incomes.income_payment">
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md3 sm4>
						<v-text-field v-model="incomes.income_date" type="datetime-local"
                        :label="$store.getters.language.data.incomes.income_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="incomes.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-textarea v-model="incomes.income_note" type="text" :label="$store.getters.language.data.incomes.income_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.incomes.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<!-- create search -->
					<v-text-field class="my-8" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="income_id">
								<template v-slot:[`item.income_amount`]="{ item }">
									<div v-if="item.income_currency == 'IQD'">
										IQD {{ item.income_amount.toLocaleString() }}
									</div>
									<div v-if="item.income_currency == 'USD'">
										$ {{ item.income_amount.toLocaleString() }}
									</div>
								</template>
                                <template v-slot:[`item.income_change_amount`]="{ item }">
                                    <div v-if="item.income_change_currency == 'IQD'">
                                        IQD {{ item.income_change_amount.toLocaleString() }}
                                    </div>
                                    <div v-if="item.income_change_currency == 'USD'">
                                        $ {{ item.income_change_amount.toLocaleString() }}
                                    </div>
                                </template>

								<template v-slot:[`item.income_date`]="{ item }">
									<div>
										{{ new Date(item.income_date).toISOString().split('T')[0]}} {{ new Date(item.income_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.income_id`]="{ item }">
									<div v-if="user.user_role==1">
										<v-btn icon :to="'/incomes-list/'+item.income_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectIncomes(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteIncomesList">{{$store.getters.language.data.incomes.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.incomes.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.incomes.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteIncomes(selected_incomes.income_id)">
						{{$store.getters.language.data.incomes.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/incomes.request.js'
	export default {
		data() {
			return {
				incomes: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_incomes: {},
				delete_dialog: false,
				headers: [

					{
						text: this.$store.getters.language.data.income_accounts.income_account_name,
						align: 'start',
						sortable: true,
						value: 'income_account_name',
					},
					{
						text: this.$store.getters.language.data.incomes.income_detail,
						align: 'start',
						sortable: true,
						value: 'income_detail',
					},
					{
						text: this.$store.getters.language.data.incomes.income_amount,
						align: 'start',
						sortable: true,
						value: 'income_amount',
					},
                    {
						text: this.$store.getters.language.data.incomes.income_change_amount,
						align: 'start',
						sortable: true,
						value: 'income_change_amount',
					},
					{
						text: this.$store.getters.language.data.incomes.income_type,
						align: 'start',
						sortable: true,
						value: 'income_type',
					},
					{
						text: this.$store.getters.language.data.incomes.income_status,
						align: 'start',
						sortable: true,
						value: 'income_status',
					},
					{
						text: this.$store.getters.language.data.incomes.income_payment,
						align: 'start',
						sortable: true,
						value: 'income_payment',
					},
					{
						text: this.$store.getters.language.data.incomes.income_note,
						align: 'start',
						sortable: true,
						value: 'income_note',
					},
					{
						text: this.$store.getters.language.data.income_accounts.income_account_phone,
						align: 'start',
						sortable: true,
						value: 'income_account_phone',
					},
					{
						text: this.$store.getters.language.data.incomes.income_date,
						align: 'start',
						sortable: true,
						value: 'income_date',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'income_id',
					}
				],
			}
		},
		computed: {
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},
			income_accounts() {
				return this.$store.getters.income_accounts_list
			},
		},
		mounted() {
			this.readIncomes();
			this.incomes.user_id = this.user.user_id
			this.incomes.income_payment = 'direct'

		},
		methods: {
			addIncomes() {
				this.loading_btn = true
				// this.incomes.income_date = new Date().toISOString().slice(0, 19).replace('T', ' ');
				//todo if income payment it not selected
				this.incomes.income_amount = this.incomes.income_amount.replace(/,/g, '');

				requests.createIncomes(this.incomes).then(r => {
					if (r.status == 200) {
						this.incomes = {
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Incomes Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Incomes',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteIncomes(income_id) {
				requests.deleteIncomes(income_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.income_id != income_id
						})
						this.snackbar = {
							value: true,
							text: 'Incomes Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteIncomesList() {
				let ids = this.selected_rows.map(m => m.income_id)
				requests.deleteIncomesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.income_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Incomes Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readIncomes() {
				this.loading = true
				requests.getAllIncomes().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Incomes',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Incomes',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectIncomes(i) {
				this.selected_incomes = i
				this.delete_dialog = true
			},
			formatIncomeAmount() {
				// Remove any non-numeric characters
				let inputValue = this.incomes.income_amount.replace(/[^\d.]/g, '');

				// Split the input value into integer and decimal parts
				let parts = inputValue.split('.');
				let integerPart = parts[0] || '0';
				let decimalPart = parts[1] || '';

				// Add commas to the integer part
				integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

				// Combine integer and decimal parts back
				let formattedValue = integerPart + (decimalPart ? '.' + decimalPart : '');

				// Update the incomes.income_amount with the formatted value
				this.incomes.income_amount = formattedValue;
			},
		},
	}
</script>
                    