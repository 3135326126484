
import axios from 'axios';
export default {
    
    async createTakeOuts(params)  {
        return await axios.post(`take_outs/create` , params)
    },
    async createTakeOutsList(params)  {
        return await axios.post(`take_outs/create/list` , params)
    },
    async updateTakeOutsColumn(column , value , data)  {
        return await axios.put(`take_outs/update_list?${column}=${value}` , data)
    },
    async deleteTakeOutsList(list)  {
        return await axios.delete(`take_outs/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportTakeOuts(column , value)  {
        return await axios.get(`take_outs/report?${column}=${value}`)
    },
    async getAllTakeOuts()  {
        return await axios.get(`take_outs/all`)
    },
    async getOneTakeOuts(take_out_id)  {
        return await axios.get(`take_outs/all/${take_out_id}`)
    },
    async getTakeOutsByColumn(column , value)  {
        return await axios.get(`take_outs/filter?column=${column}&value=${value}`)
    },
    async deleteTakeOuts(take_out_id)  {
        return await axios.delete(`take_outs/delete/${take_out_id}`)
    },
    async updateTakeOuts(take_out_id , params)  {
        return await axios.put(`take_outs/update/${take_out_id}` , params)
    }
}