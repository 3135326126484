
<template>
    <div v-if="user.user_role==2">
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="my-6">Total Added Money Balance  :</h1>

			<!-- <form @submit.prevent="addTotalAddedMoneys" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="total_added_moneys.total_added_money_amount" type="text" :label="$store.getters.language.data.total_added_moneys.total_added_money_amount" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="total_added_moneys.total_added_money_date" type="datetime-local" :label="$store.getters.language.data.total_added_moneys.total_added_money_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.total_added_moneys.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form> -->
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="total_added_money_id">
								<template v-slot:[`item.total_added_money_id`]="{ item }">
									<div>
										<v-btn icon :to="'/total_added_moneys-list/'+item.total_added_money_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectTotalAddedMoneys(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteTotalAddedMoneysList">{{$store.getters.language.data.total_added_moneys.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.total_added_moneys.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.total_added_moneys.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteTotalAddedMoneys(selected_total_added_moneys.total_added_money_id)">
						{{$store.getters.language.data.total_added_moneys.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/total_added_moneys.request.js'
	export default {
		data() {
			return {
				total_added_moneys: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_total_added_moneys: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.total_added_moneys.total_added_money_amount,
						align: 'start',
						sortable: true,
						value: 'total_added_money_amount',
					},
					{
						text: this.$store.getters.language.data.total_added_moneys.total_added_money_date,
						align: 'start',
						sortable: true,
						value: 'total_added_money_date',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'total_added_money_id',
					}
				],
			}
		},
		computed: {
            user() {
                return this.$store.getters.user
            }
		},
		mounted() {
			// this.readTotalAddedMoneys();
		},
		methods: {
			addTotalAddedMoneys() {
				this.loading_btn = true
				requests.createTotalAddedMoneys(this.total_added_moneys).then(r => {
					if (r.status == 200) {
						this.total_added_moneys = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'TotalAddedMoneys Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add TotalAddedMoneys',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteTotalAddedMoneys(total_added_money_id) {
				requests.deleteTotalAddedMoneys(total_added_money_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.total_added_money_id != total_added_money_id
						})
						this.snackbar = {
							value: true,
							text: 'TotalAddedMoneys Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteTotalAddedMoneysList() {
				let ids = this.selected_rows.map(m => m.total_added_money_id)
				requests.deleteTotalAddedMoneysList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.total_added_money_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' TotalAddedMoneys Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readTotalAddedMoneys() {
				this.loading = true
				requests.getAllTotalAddedMoneys().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read TotalAddedMoneys',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read TotalAddedMoneys',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectTotalAddedMoneys(i) {
				this.selected_total_added_moneys = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    