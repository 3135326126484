
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta: {
            auth: false,
            title: 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },

    {
        path: '/added_moneys-list',
        name: 'ListAdded_moneys',
        component: () => import('./../views/Added_moneys/List.vue'),
        meta: {
            auth: true,
            title: 'Added_moneys List'
        }
    },
    {
        path: '/added_moneys-list/:id',
        name: 'ViewAdded_moneys',
        component: () => import('./../views/Added_moneys/Edit.vue'),
        meta: {
            auth: true,
            title: 'Added_moneys Edit'
        }
    },
    {
        path: '/expenses-list',
        name: 'ListExpenses',
        component: () => import('./../views/Expenses/List.vue'),
        meta: {
            auth: true,
            title: 'Expenses List'
        }
    },
    {
        path: '/expenses-list/:id',
        name: 'ViewExpenses',
        component: () => import('./../views/Expenses/Edit.vue'),
        meta: {
            auth: true,
            title: 'Expenses Edit'
        }
    },
    {
        path: '/incomes-list',
        name: 'ListIncomes',
        component: () => import('./../views/Incomes/List.vue'),
        meta: {
            auth: true,
            title: 'Incomes List'
        }
    },
    {
        path: '/incomes-list/:id',
        name: 'ViewIncomes',
        component: () => import('./../views/Incomes/Edit.vue'),
        meta: {
            auth: true,
            title: 'Incomes Edit'
        }
    },
    {
        path: '/payment_accounts-list',
        name: 'ListPayment_accounts',
        component: () => import('./../views/Payment_accounts/List.vue'),
        meta: {
            auth: true,
            title: 'Payment_accounts List'
        }
    },
    {
        path: '/payment_accounts-list/:id',
        name: 'ViewPayment_accounts',
        component: () => import('./../views/Payment_accounts/Edit.vue'),
        meta: {
            auth: true,
            title: 'Payment_accounts Edit'
        }
    },
    {
        path: '/payments-list',
        name: 'ListPayments',
        component: () => import('./../views/Payments/List.vue'),
        meta: {
            auth: true,
            title: 'Payments List'
        }
    },
    {
        path: '/payments-list/:id',
        name: 'ViewPayments',
        component: () => import('./../views/Payments/Edit.vue'),
        meta: {
            auth: true,
            title: 'Payments Edit'
        }
    },
    {
        path: '/total_added_moneys-list',
        name: 'ListTotal_added_moneys',
        component: () => import('./../views/Total_added_moneys/List.vue'),
        meta: {
            auth: true,
            title: 'Total_added_moneys List'
        }
    },
    {
        path: '/total_added_moneys-list/:id',
        name: 'ViewTotal_added_moneys',
        component: () => import('./../views/Total_added_moneys/Edit.vue'),
        meta: {
            auth: true,
            title: 'Total_added_moneys Edit'
        }
    },
    {
        path: '/reports/',
        name: 'Reports',
        component: () => import('./../views/Reports/Reports.vue'),
        meta: {
            auth: true,
            title: 'Report'
        }
    },
    {
        path: '/users-list',
        name: 'ListUsers',
        component: () => import('./../views/Users/List.vue'),
        meta: {
            auth: true,
            title: 'Users List'
        }
    },
    {
        path: '/users-list/:id',
        name: 'ViewUsers',
        component: () => import('./../views/Users/Edit.vue'),
        meta: {
            auth: true,
            title: 'Users Edit'
        }
    },
    {
        path: '/income_accounts-list',
        name: 'ListIncome_accounts',
        component: () => import('./../views/Income_accounts/List.vue'),
        meta: {
            auth: true,
            title: 'Income_accounts List'
        }
    },
    {
        path: '/income_accounts-list/:id',
        name: 'ViewIncome_accounts',
        component: () => import('./../views/Income_accounts/Edit.vue'),
        meta: {
            auth: true,
            title: 'Income_accounts Edit'
        }
    },
    {
        path: '/payment_categories-list',
        name: 'ListPayment_categories',
        component: () => import('./../views/Payment_categories/List.vue'),
        meta: {
            auth: true,
            title: 'Payment_categories List'
        }
    },
    {
        path: '/payment_categories-list/:id',
        name: 'ViewPayment_categories',
        component: () => import('./../views/Payment_categories/Edit.vue'),
        meta: {
            auth: true,
            title: 'Payment_categories Edit'
        }
    },
    {
        path: '/take_outs-list',
        name: 'ListTake_outs',
        component: () => import('./../views/Take_outs/List.vue'),
        meta: {
            auth: true,
            title: 'Take_outs List'
        }
    },
    {
        path: '/take_outs-list/:id',
        name: 'ViewTake_outs',
        component: () => import('./../views/Take_outs/Edit.vue'),
        meta: {
            auth: true,
            title: 'Take_outs Edit'
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('./../views/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },
]
const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
