<template>
	<div>
		<v-container px-1>
			<h1>Income Report</h1>
			<v-divider></v-divider>
			<!-- <v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							<th>
    money type
							</th>
							<th>
								{{$store.getters.language.data.accounts.total_income}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in groups" :key="index">
							<td>{{index+1}}</td>
							<td>{{item.money_move_type_name}}</td>
							<td>{{item.total.toLocaleString()}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout> -->
			<!-- <v-layout> -->
				<!-- <h1 class="mt-8">
					Total Incomes
					: {{total.total.toLocaleString()}}
				</h1> -->
			<!-- </v-layout> -->

			<!-- make the layout item under each other -->
			
            
			<v-divider class="py-4"></v-divider>
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in total_currency" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.income_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
            
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Total Amount</th>
							<th>Currency</th>
                            <th>Status</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in total_currency_status" :key="index">
							
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
                            <td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.income_currency }}
								</p>
							</td>
                            <td class="item-wrapper">
                                <p style="margin: 3px 0px">
                                    {{ item.income_status }}
                                </p>
                            </td>
						</tr>
					</tbody>
				</table>
			</v-layout>
            <v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Total Amount</th>
							<th>Type</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in groups" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.income_currency }} 	{{ item.total.toLocaleString() }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
								{{ item.income_type }}
								</p>
							</td>
                            <td>
                                <p style="margin: 3px 0px">
                                {{ item.income_status }}
                                </p>
                            </td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							<th>
								ID
							</th>
							<th>
								{{$store.getters.language.data.incomes.income_date}}
							</th>
							<th>
								{{$store.getters.language.data.incomes.income_detail}}
							</th>
							<th>
								{{$store.getters.language.data.incomes.income_amount}}
							</th>
                            <th>
                                Change 
                            </th>
							<th>
								Type
							</th>
							<th>
								Status
							</th>
							<th>
								Payment
							</th>
                           
							<th>
								{{$store.getters.language.data.report.note}}
							</th>
							<th>
								Account
							</th>
							<th>
								Phone
							</th>
							<th>
								{{$store.getters.language.data.users.user_name}}
							</th>

							<!-- <th>
								{{$store.getters.language.data.accounts.account_name}}
							</th> -->
							<!-- <th>
								{{$store.getters.language.data.report.amount}}
							</th> -->
							<!-- <th>
								{{$store.getters.language.data.incomes.income_note}}
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in rows" :key="index">
							<td>{{index+1}}</td>
							<td>{{item.income_id}}</td>
							<!-- <td>{{item.money_move_type_name}}</td> -->
							<td>{{ new Date(item.income_date).toISOString().split('T')[0]}} {{ new Date(item.income_date).toISOString().split('T')[1].split('.')[0]}}</td>
							<!-- <td>{{item.account_name}}</td> -->
							<td>{{item.income_detail}}</td>
							<td>
								<div v-if="item.income_currency == 'USD'">$ {{item.income_amount.toLocaleString()}}</div>
								<div v-else>
									{{item.income_amount.toLocaleString()}} IQD
								</div>
							</td>
                            <td>
                                {{ item.income_change_currency }}
                                {{ item.income_change_amount }}
                            </td>
							<td>{{item.income_type}}</td>
							<td>

								<div v-if="item.income_status == 'paid'">
									<v-icon color="green">mdi-check</v-icon>
									{{item.income_status}}
								</div>
								<div v-else>
									<v-icon color="red">mdi-close</v-icon>
									{{item.income_status}}
								</div>

							</td>
							<td>{{item.income_payment}}</td>
							<td>{{item.income_note}}</td>
							<td>{{item.income_account_name}}</td>
							<td>{{item.income_account_phone}}</td>
							<td>{{item.user_name}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout>

		</v-container>
	</div>
</template>
<script>
	import requests from '../../../requests/reports.requests'
	export default {
		data() {
			return {
				rows: [],
				total: {},
				groups: [],
				total_currency: [],
                total_currency_status: [],
				loading: false,
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				requests.incomesReport(query).then(res => {
					this.rows = res.data.rows
					this.total = res.data.total
					this.total_currency = res.data.total_currency
                    this.total_currency_status = res.data.total_currency_status
					this.groups = res.data.total_group
					this.loading = false
				})
			}
		},
	}
</script>