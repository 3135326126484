
import axios from 'axios';
export default {
    
    async createTotalAddedMoneys(params)  {
        return await axios.post(`total_added_moneys/create` , params)
    },
    async createTotalAddedMoneysList(params)  {
        return await axios.post(`total_added_moneys/create/list` , params)
    },
    async updateTotalAddedMoneysColumn(column , value , data)  {
        return await axios.put(`total_added_moneys/update_list?${column}=${value}` , data)
    },
    async deleteTotalAddedMoneysList(list)  {
        return await axios.delete(`total_added_moneys/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportTotalAddedMoneys(column , value)  {
        return await axios.get(`total_added_moneys/report?${column}=${value}`)
    },
    async getAllTotalAddedMoneys()  {
        return await axios.get(`total_added_moneys/all`)
    },
    async getOneTotalAddedMoneys(total_added_money_id)  {
        return await axios.get(`total_added_moneys/all/${total_added_money_id}`)
    },
    async getTotalAddedMoneysByColumn(column , value)  {
        return await axios.get(`total_added_moneys/filter?column=${column}&value=${value}`)
    },
    async deleteTotalAddedMoneys(total_added_money_id)  {
        return await axios.delete(`total_added_moneys/delete/${total_added_money_id}`)
    },
    async updateTotalAddedMoneys(total_added_money_id , params)  {
        return await axios.put(`total_added_moneys/update/${total_added_money_id}` , params)
    }
}