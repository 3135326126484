
import axios from 'axios';
export default {
    
    async createPaymentCategories(params)  {
        return await axios.post(`payment_categories/create` , params)
    },
    async createPaymentCategoriesList(params)  {
        return await axios.post(`payment_categories/create/list` , params)
    },
    async updatePaymentCategoriesColumn(column , value , data)  {
        return await axios.put(`payment_categories/update_list?${column}=${value}` , data)
    },
    async deletePaymentCategoriesList(list)  {
        return await axios.delete(`payment_categories/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportPaymentCategories(column , value)  {
        return await axios.get(`payment_categories/report?${column}=${value}`)
    },
    async getAllPaymentCategories()  {
        return await axios.get(`payment_categories/all`)
    },
    async getOnePaymentCategories(payment_category_id)  {
        return await axios.get(`payment_categories/all/${payment_category_id}`)
    },
    async getPaymentCategoriesByColumn(column , value)  {
        return await axios.get(`payment_categories/filter?column=${column}&value=${value}`)
    },
    async deletePaymentCategories(payment_category_id)  {
        return await axios.delete(`payment_categories/delete/${payment_category_id}`)
    },
    async updatePaymentCategories(payment_category_id , params)  {
        return await axios.put(`payment_categories/update/${payment_category_id}` , params)
    }
}