
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="my-6">Add Payment Account :</h1>

			<form @submit.prevent="addPaymentAccounts" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="payment_accounts.payment_account_name" type="text" :label="$store.getters.language.data.payment_accounts.payment_account_name" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="payment_accounts.payment_account_phone" type="text" :label="$store.getters.language.data.payment_accounts.payment_account_phone" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="payment_accounts.payment_account_date" type="datetime-local" :label="$store.getters.language.data.payment_accounts.payment_account_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex> -->

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="payment_accounts.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-textarea v-model="payment_accounts.payment_account_note" type="text" :label="$store.getters.language.data.payment_accounts.payment_account_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.payment_accounts.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field class="my-8" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="payment_account_id">
								<template v-slot:[`item.payment_account_date`]="{ item }">
									<div>
										{{ new Date(item.payment_account_date).toISOString().split('T')[0]}} {{ new Date(item.payment_account_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.payment_account_id`]="{ item }">
									<div v-if="user.user_role==1">
										<v-btn icon :to="'/payment_accounts-list/'+item.payment_account_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectPaymentAccounts(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deletePaymentAccountsList">{{$store.getters.language.data.payment_accounts.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.payment_accounts.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.payment_accounts.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deletePaymentAccounts(selected_payment_accounts.payment_account_id)">
						{{$store.getters.language.data.payment_accounts.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/payment_accounts.request.js'
	export default {
		data() {
			return {
				payment_accounts: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_payment_accounts: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.payment_accounts.payment_account_name,
						align: 'start',
						sortable: true,
						value: 'payment_account_name',
					},
					{
						text: this.$store.getters.language.data.payment_accounts.payment_account_note,
						align: 'start',
						sortable: true,
						value: 'payment_account_note',
					},
					{
						text: this.$store.getters.language.data.payment_accounts.payment_account_phone,
						align: 'start',
						sortable: true,
						value: 'payment_account_phone',
					},
					{
						text: this.$store.getters.language.data.payment_accounts.payment_account_date,
						align: 'start',
						sortable: true,
						value: 'payment_account_date',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'payment_account_id',
					}
				],
			}
		},
		computed: {
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			}
		},
		mounted() {
			this.readPaymentAccounts();
			this.payment_accounts.user_id = this.user.user_id
		},
		methods: {
			addPaymentAccounts() {
				this.loading_btn = true
				// this.payment_accounts.payment_account_date = new Date().toISOString().slice(0, 19).replace('T', ' ');

				requests.createPaymentAccounts(this.payment_accounts).then(r => {
					if (r.status == 200) {
						this.payment_accounts = {
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'PaymentAccounts Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add PaymentAccounts',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deletePaymentAccounts(payment_account_id) {
				requests.deletePaymentAccounts(payment_account_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.payment_account_id != payment_account_id
						})
						this.snackbar = {
							value: true,
							text: 'PaymentAccounts Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deletePaymentAccountsList() {
				let ids = this.selected_rows.map(m => m.payment_account_id)
				requests.deletePaymentAccountsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.payment_account_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' PaymentAccounts Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readPaymentAccounts() {
				this.loading = true
				requests.getAllPaymentAccounts().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read PaymentAccounts',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read PaymentAccounts',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectPaymentAccounts(i) {
				this.selected_payment_accounts = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    