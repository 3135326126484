
<template>
	<div v-if="user.user_role==1">
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<v-layout column class="my-7">
				<h1>Income Edit</h1>
				<v-divider></v-divider>
			</v-layout>
			<form @submit.prevent="updateIncomes" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="income_accounts" v-model="incomes.income_account_id" dense filled outlined item-text="income_account_name" item-value="income_account_id" :return-object="false" :label="$store.getters.language.data.income_accounts.income_account_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="income_accounts" v-model="incomes.income_account_id" dense filled outlined item-text="income_account_phone" item-value="income_account_id" :return-object="false" :label="$store.getters.language.data.income_accounts.income_account_phone">
						</v-select>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="incomes.income_detail" type="text" :label="$store.getters.language.data.incomes.income_detail" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="incomes.income_amount" type="text" :label="$store.getters.language.data.incomes.income_amount" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="['IQD','USD']" v-model="incomes.income_currency" dense filled outlined item-text="income_currency" item-value="income_currency" :return-object="false" :label="$store.getters.language.data.incomes.income_currency" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="['inshop','storage']" v-model="incomes.income_type" dense filled outlined item-text="income_type" item-value="income_type" :return-object="false" :label="$store.getters.language.data.incomes.income_type">
						</v-select>
					</v-flex>

					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="['paid','unpaid']" v-model="incomes.income_status" dense filled outlined item-text="income_status" item-value="income_status" :return-object="false" :label="$store.getters.language.data.incomes.income_status">
						</v-select>
					</v-flex>

					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="['hyperpost','postponed','direct','fib','fastpay','zaincash','asiahawala']" v-model="incomes.income_payment" dense filled outlined item-text="income_payment" item-value="income_payment" :return-object="false" :label="$store.getters.language.data.incomes.income_payment">
						</v-select>
					</v-flex>

					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-text-field v-model="incomes.income_note" type="text" :label="$store.getters.language.data.incomes.income_note" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="incomes.income_date" type="datetime-local" :label="$store.getters.language.data.incomes.income_date" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="incomes.income_payment_date" type="datetime-local" :label="$store.getters.language.data.incomes.income_payment_date" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex> -->
					<v-flex xs12 lg3 xl3 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="incomes.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.incomes.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/incomes.request.js'
	export default {
		data() {
			return {
				incomes: {},
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			users() {
				return this.$store.getters.users_list
			},

			income_accounts() {
				return this.$store.getters.income_accounts_list
			},
            user(){
                return this.$store.getters.user
            }

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneIncomes()
		},
		methods: {
			getOneIncomes() {
				this.loading = true
				requests.getOneIncomes(this.id).then(r => {
					if (r.status == 200) {
						this.incomes = r.data.row
                        this.incomes.user_id = this.user.user_id
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Incomes',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Incomes',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateIncomes() {
				this.loading = true
				delete this.incomes.income_date
				delete this.incomes.income_payment_date
				requests.updateIncomes(this.id, this.incomes).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'Incomes Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    