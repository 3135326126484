
import axios from 'axios';
export default {
    
    async createPayments(params)  {
        return await axios.post(`payments/create` , params)
    },
    async createPaymentsList(params)  {
        return await axios.post(`payments/create/list` , params)
    },
    async updatePaymentsColumn(column , value , data)  {
        return await axios.put(`payments/update_list?${column}=${value}` , data)
    },
    async deletePaymentsList(list)  {
        return await axios.delete(`payments/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportPayments(column , value)  {
        return await axios.get(`payments/report?${column}=${value}`)
    },
    async getAllPayments()  {
        return await axios.get(`payments/all`)
    },
    async getOnePayments(payment_id)  {
        return await axios.get(`payments/all/${payment_id}`)
    },
    async getPaymentsByColumn(column , value)  {
        return await axios.get(`payments/filter?column=${column}&value=${value}`)
    },
    async deletePayments(payment_id)  {
        return await axios.delete(`payments/delete/${payment_id}`)
    },
    async updatePayments(payment_id , params)  {
        return await axios.put(`payments/update/${payment_id}` , params)
    }
}