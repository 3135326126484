
import axios from 'axios';
export default {
    
    async createAddedMoneys(params)  {
        return await axios.post(`added_moneys/create` , params)
    },
    async createAddedMoneysList(params)  {
        return await axios.post(`added_moneys/create/list` , params)
    },
    async updateAddedMoneysColumn(column , value , data)  {
        return await axios.put(`added_moneys/update_list?${column}=${value}` , data)
    },
    async deleteAddedMoneysList(list)  {
        return await axios.delete(`added_moneys/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportAddedMoneys(column , value)  {
        return await axios.get(`added_moneys/report?${column}=${value}`)
    },
    async getAllAddedMoneys()  {
        return await axios.get(`added_moneys/all`)
    },
    async getOneAddedMoneys(added_money_id)  {
        return await axios.get(`added_moneys/all/${added_money_id}`)
    },
    async getAddedMoneysByColumn(column , value)  {
        return await axios.get(`added_moneys/filter?column=${column}&value=${value}`)
    },
    async deleteAddedMoneys(added_money_id)  {
        return await axios.delete(`added_moneys/delete/${added_money_id}`)
    },
    async updateAddedMoneys(added_money_id , params)  {
        return await axios.put(`added_moneys/update/${added_money_id}` , params)
    }
}