
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="my-6">Add Money for Open Balance:</h1>
            <div v-if="user.user_role==1">
			<form @submit.prevent="addAddedMoneys" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-text-field v-model="added_moneys.added_money_amount" type="text" :label="$store.getters.language.data.added_moneys.added_money_amount" dense class="mx-1" @input="formatMoneyAmount" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['IQD','USD']" v-model="added_moneys.added_money_currency" dense filled outlined item-text="added_money_currency" item-value="added_money_currency" :return-object="false" :label="$store.getters.language.data.added_moneys.added_money_currency">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['pocket','income']" v-model="added_moneys.added_money_source" dense filled outlined item-text="added_money_source" item-value="added_money_source" :return-object="false" :label="$store.getters.language.data.added_moneys.added_money_source">
						</v-select>
					</v-flex>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="added_moneys.added_money_date" type="datetime-local" :label="$store.getters.language.data.added_moneys.added_money_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex> -->

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="added_moneys.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-textarea v-model="added_moneys.added_money_note" type="text" :label="$store.getters.language.data.added_moneys.added_money_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.added_moneys.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
            </div>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field class="my-8" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="added_money_id">
								<template v-slot:[`item.added_money_amount`]="{ item }">
									<div v-if="item.added_money_currency == 'IQD'">
										IQD {{ item.added_money_amount.toLocaleString() }}
									</div>
									<div v-if="item.added_money_currency == 'USD'">
										$ {{ item.added_money_amount.toLocaleString() }}
									</div>
								</template>

								<template v-slot:[`item.added_money_date`]="{ item }">
									<div>
										{{ new Date(item.added_money_date).toISOString().split('T')[0]}} {{ new Date(item.added_money_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.added_money_id`]="{ item }">
									<div v-if="user.user_role==1">
										<v-btn icon :to="'/added_moneys-list/'+item.added_money_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectAddedMoneys(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteAddedMoneysList">{{$store.getters.language.data.added_moneys.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.added_moneys.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.added_moneys.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteAddedMoneys(selected_added_moneys.added_money_id)">
						{{$store.getters.language.data.added_moneys.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/added_moneys.request.js'
	export default {
		data() {
			return {
				added_moneys: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_added_moneys: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.added_moneys.added_money_amount,
						align: 'start',
						sortable: true,
						value: 'added_money_amount',
					},
					{
						text: this.$store.getters.language.data.added_moneys.added_money_source,
						align: 'start',
						sortable: true,
						value: 'added_money_source',
					},
					{
						text: this.$store.getters.language.data.added_moneys.added_money_note,
						align: 'start',
						sortable: true,
						value: 'added_money_note',
					},
					{
						text: this.$store.getters.language.data.added_moneys.added_money_date,
						align: 'start',
						sortable: true,
						value: 'added_money_date',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'added_money_id',
					}
				],
			}
		},
		computed: {
			user() {
				return this.$store.getters.user
			},
			users() {
				return this.$store.getters.users_list
			}
		},
		mounted() {
			this.readAddedMoneys();
			this.added_moneys.user_id = this.user.user_id
		},
		methods: {
			addAddedMoneys() {
				this.loading_btn = true
				// add right now date and time to added_money_date
				// this.added_moneys.added_money_date = new Date().toISOString().slice(0, 19).replace('T', ' ');
                this.added_moneys.added_money_amount = this.added_moneys.added_money_amount.replace(/,/g, '');
				requests.createAddedMoneys(this.added_moneys).then(r => {
					if (r.status == 200) {
						this.added_moneys = {
							user_id: this.user.user_id,
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'AddedMoneys Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add AddedMoneys',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteAddedMoneys(added_money_id) {
				requests.deleteAddedMoneys(added_money_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.added_money_id != added_money_id
						})
						this.snackbar = {
							value: true,
							text: 'AddedMoneys Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteAddedMoneysList() {
				let ids = this.selected_rows.map(m => m.added_money_id)
				requests.deleteAddedMoneysList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.added_money_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' AddedMoneys Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readAddedMoneys() {
				this.loading = true
				requests.getAllAddedMoneys().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read AddedMoneys',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read AddedMoneys',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectAddedMoneys(i) {
				this.selected_added_moneys = i
				this.delete_dialog = true
			},
            formatMoneyAmount() {
				// Remove any non-numeric characters
				let inputValue = this.added_moneys.added_money_amount.replace(/[^\d.]/g, '');

				// Split the input value into integer and decimal parts
				let parts = inputValue.split('.');
				let integerPart = parts[0] || '0';
				let decimalPart = parts[1] || '';

				// Add commas to the integer part
				integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

				// Combine integer and decimal parts back
				let formattedValue = integerPart + (decimalPart ? '.' + decimalPart : '');

				// Update the added_moneys.added_money_amount with the formatted value
				this.added_moneys.added_money_amount = formattedValue;
			},
		},
	}
</script>
                    