
import axios from 'axios';
export default {
    async incomesReport(params) {
        return await axios.post(`reports/incomes`, params)
    },
    async expensesReport(params) {
        return await axios.post(`reports/expenses`, params)
    },
    async addedMoneyReport(params) {
        return await axios.post(`reports/addedmoney`, params)
    },
    async paymentsReport(params) {
        return await axios.post(`reports/payments`, params)
    },
    async getHomeData() {
        return await axios.post(`reports/home`)
    },
    async totalReport(params) {
        return await axios.post(`reports/total`, params)
    },
    async takeoutReport(params) {
        return await axios.post(`reports/takeouts`, params)
    },
    async totalBank(params) {
        return await axios.post(`reports/totalbank`, params)
    }



    
}
