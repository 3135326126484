
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>

		<v-container v-else class="my-2">
			<v-layout row wrap mt-4>
				<v-flex xs12>
					<h1 class="text-center">{{user.user_name}} Welcome Back! </h1>
					<v-divider></v-divider>
					<!-- write right now date -->
					<h2 class="text-center">Today is {{new Date().toLocaleDateString()}}</h2>
				</v-flex>
			</v-layout>
			<!-- btn on click this.readBank(); -->
			<v-layout my-7>
				<v-flex xs12 class="text-center">
					<v-btn color="primary" @click="readBank()">Read Bank</v-btn>
				</v-flex>
			</v-layout>
			<v-layout row wrap mt-4 v-if="bankLoaded">
				<v-flex xs12>
                    <h2 class="text-center" style="color:green" >INSIDE BANK RIGHT NOW :  {{ bank_iqd - total_money_iqd }} IQD</h2>
					<h1 class="text-center">Bank IQD : {{bank_iqd}}</h1>
					<h1 class="text-center">Bank USD : {{bank_usd}}</h1>

				</v-flex>
			</v-layout>
			<v-layout row wrap class="my-10" justify-center v-if="dataLoaded && dataLoaded2">
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total Opening Balance Dinar</div>
								<v-list-item-title class="headline mb-1">
									<!-- if {{total_money}} was positive show in green if it was negative show in red and bold -->
									<span :class="{'green--text': total_money_iqd > 0, 'red--text': total_money_iqd < 0, 'font-weight-bold': total_money_iqd != 0}">
										IQD {{(total_money_iqd|| 0).toLocaleString()}}
									</span>

								</v-list-item-title>
								<v-list-item-subtitle>Available</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total Opening Balance Dollar</div>
								<v-list-item-title class="headline mb-1">
									<!-- if {{total_money}} was positive show in green if it was negative show in red and bold -->
									<span :class="{'green--text': total_money_usd > 0, 'red--text': total_money_usd < 0, 'font-weight-bold': total_money_usd != 0}">
										$ {{(total_money_usd|| 0).toLocaleString()}}
									</span>

								</v-list-item-title>
								<v-list-item-subtitle>Available</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total take_out Dinar</div>
								<v-list-item-title class="headline mb-1">IQD {{(total_take_out_iqd|| 0).toLocaleString()}}</v-list-item-title>
								<v-list-item-subtitle>Today</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total take_out Dollar</div>
								<v-list-item-title class="headline mb-1">$ {{(total_take_out_usd|| 0).toLocaleString()}}</v-list-item-title>
								<v-list-item-subtitle>Today</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total Income Dinar</div>
								<v-list-item-title class="headline mb-1">IQD {{(total_income_iqd|| 0).toLocaleString()}}</v-list-item-title>
								<v-list-item-subtitle>Today</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total Income Dollar</div>
								<v-list-item-title class="headline mb-1">$ {{(total_income_usd|| 0).toLocaleString()}}</v-list-item-title>
								<v-list-item-subtitle>Today</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total Payments Dinar</div>
								<v-list-item-title class="headline mb-1">IQD {{ (today_payment_iqd|| 0).toLocaleString() }}</v-list-item-title>
								<v-list-item-subtitle>Today</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>
				<v-flex xs12 lg3 xl3 md3 sm4 class="ma-3">
					<v-card class="mx-auto" outlined>
						<v-list-item three-line>
							<v-list-item-content>
								<div class="overline mb-4">Total Payments Dollar</div>
								<v-list-item-title class="headline mb-1">$ {{ (today_payment_usd|| 0).toLocaleString() }}</v-list-item-title>
								<v-list-item-subtitle>Today</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-card-actions>
                <v-btn text>View</v-btn>
                </v-card-actions> -->
					</v-card>
				</v-flex>

			</v-layout>
			<v-layout row wrap class="my-10" justify-center v-if="!dataLoaded">
				no-data
			</v-layout>
			<v-layout row wrap class="my-10" justify-center v-if="!dataLoaded">
				no-data
			</v-layout>

		</v-container>
	</div>
</template>
<script>
	import requestsMoney from './../requests/total_added_moneys.request.js'
	import requestsHomeData from './../requests/reports.requests'


	export default {
		data() {
			return {
				total_money: 0,
				total_money_iqd: 0,
				total_money_usd: 0,
				total_income_iqd: 0,
				total_income_usd: 0,
				total_take_out_iqd: 0,
				total_take_out_usd: 0,
				total_payment_iqd: 0,
				total_payment_usd: 0,
				bank_iqd: 0,
				bank_usd: 0,
				rows: [],
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: '',
				},
				today_payment: { total: 0 }, // Initialize with default value
				dataLoaded: false, // Initialize as false
				dataLoaded2: false, // Initialize as false
				bankLoaded: false, // Initialize as false


			}
		},
		computed: {
			user() {
				return this.$store.getters.user
			},
		},
		mounted() {
			this.readHomeData();
			this.readTotalAddedMoneys();


		},
		navigation_links() {
			return [

				{
					path: '/added_moneys-list',
					title: 'added_moneys',
				},

				{
					path: '/expenses-list',
					title: 'expenses',
				},

				{
					path: '/incomes-list',
					title: 'incomes',
				},

				{
					path: '/payment_accounts-list',
					title: 'payment_accounts',
				},

				{
					path: '/payments-list',
					title: 'payments',
				},

				{
					path: '/total_added_moneys-list',
					title: 'total_added_moneys',
				},

				{
					path: '/users-list',
					title: 'users',
				},

			]
		},
		methods: {
			readTotalAddedMoneys() {
				this.loading = true
				requestsMoney.getAllTotalAddedMoneys().then(r => {
					if (r.status == 200) {
						this.total_money_iqd = r.data.rows[0].total_added_money_amount
						this.total_money_usd = r.data.rows[1].total_added_money_amount
						this.loading = false
						this.dataLoaded2 = true
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read TotalAddedMoneys',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read TotalAddedMoneys',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readHomeData() {
				this.loading = true
				requestsHomeData.getHomeData().then(r => {
					if (r.status == 200) {

						this.rows = r.data
						console.log(this.rows)

						// if (this.rows.today_expense.total == null) {
						// 	this.rows.today_expense.total = 0
						// }
						if (this.rows.today_income_iqd.total == null) {
							this.rows.today_income_iqd.total = 0
						}
						if (this.rows.today_income_usd.total == null) {
							this.rows.today_income_usd.total = 0
						}
						if (this.rows.today_take_out_iqd.total == null) {
							this.rows.today_take_out_iqd.total = 0
						}
						if (this.rows.today_take_out_usd.total == null) {
							this.rows.today_take_out_usd.total = 0
						}
						if (this.rows.today_payment_iqd.total == null) {
							this.rows.today_payment_iqd.total = 0
						}
						if (this.rows.today_payment_usd.total == null) {
							this.rows.today_payment_usd.total = 0
						}

						this.total_income_iqd = this.rows.today_income_iqd.total
						this.total_income_usd = this.rows.today_income_usd.total
						this.total_take_out_iqd = this.rows.today_take_out_iqd.total
						this.total_take_out_usd = this.rows.today_take_out_usd.total
						this.today_payment_iqd = this.rows.today_payment_iqd.total
						this.today_payment_usd = this.rows.today_payment_usd.total


						//    console.log(this.rows.today_take_out.total)
						this.dataLoaded = true; // Set dataLoaded to true when data is available

						// this.$forceUpdate();

						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Data',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Data',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			readBank() {
                this.loading = true
				requestsHomeData.totalBank().then(r => {
					if (r.status == 200) {
						this.bank_iqd = r.data.total_added_money_pocket[0].total_bank_iqd
						this.bank_usd = r.data.total_added_money_pocket[0].total_bank_usd
						this.bankLoaded = true
                        this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read TotalAddedMoneys',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read TotalAddedMoneys',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	}
</script>