<template>
	<div>
		<v-container px-1>
			<h1>Take Out Report</h1>
			<v-divider></v-divider>
			<!-- <v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							<th>
    money type
							</th>
							<th>
								{{$store.getters.language.data.accounts.total_income}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in groups" :key="index">
							<td>{{index+1}}</td>
							<td>{{item.money_move_type_name}}</td>
							<td>{{item.total.toLocaleString()}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout> -->
			<!-- <v-layout>
				<h1 class="mt-8">
					Total take_outs
					: {{total.total.toLocaleString()}}
				</h1>
			</v-layout> -->

			<!-- make the layout item under each other -->
			<!-- <v-layout column>
				<div v-for="(item, index) in groups" :key="index" class="item-wrapper" >
					<p style="margin:3px 0px">
						{{ item.income_type }}: {{ item.total.toLocaleString() }}
					</p>
				</div>
			</v-layout> -->
			<v-divider class="py-4"></v-divider>
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Take Outs</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in total" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.take_out_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							<!-- <th>
								{{$store.getters.language.data.money_move_type.money_move_type_name}}
							</th> -->
							<th>
								{{$store.getters.language.data.take_outs.take_out_date}}
							</th>
							<th>
								{{$store.getters.language.data.take_outs.take_out_amount}}
							</th>
							<th>
								{{$store.getters.language.data.take_outs.take_out_note}}
							</th>

							<th>
								{{$store.getters.language.data.users.user_name}}
							</th>

							<!-- <th>
								{{$store.getters.language.data.accounts.account_name}}
							</th> -->
							<!-- <th>
								{{$store.getters.language.data.report.amount}}
							</th> -->
							<!-- <th>
								{{$store.getters.language.data.incomes.income_note}}
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in rows" :key="index">
							<td>{{index+1}}</td>
							<!-- <td>{{item.money_move_type_name}}</td> -->
							<td>{{ new Date(item.take_out_date).toISOString().split('T')[0]}} {{ new Date(item.take_out_date).toISOString().split('T')[1].split('.')[0]}}</td>
							<!-- <td>{{item.account_name}}</td> -->
							<td>{{item.take_out_currency}} {{item.take_out_amount.toLocaleString()}}</td>

							<td>{{item.take_out_note}}</td>

							<td>{{item.user_name}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout>

		</v-container>
	</div>
</template>
<script>
	import requests from '../../../requests/reports.requests'
	export default {
		data() {
			return {
				rows: [],
				total: {},
				groups: [],
				total_currency: [],
				loading: false,
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				requests.takeoutReport(query).then(res => {
					this.rows = res.data.rows
					this.total = res.data.total
					// this.groups = res.data.total_group
					// this.total_currency = res.data.total_currency
					this.loading = false
				})
			}
		},
	}
</script>