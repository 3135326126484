<template>
	<div>
		<v-container px-1>
			<h1>Payments Report</h1>
			<v-divider></v-divider>
			<!-- <v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							<th>
    money type
							</th>
							<th>
								{{$store.getters.language.data.accounts.total_income}}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in groups" :key="index">
							<td>{{index+1}}</td>
							<td>{{item.money_move_type_name}}</td>
							<td>{{item.total.toLocaleString()}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout> -->
			<!-- <v-layout>
				<h1 class="mt-8">
					Total Payments
					: {{total.total.toLocaleString()}}
				</h1>
			</v-layout> -->
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Currency</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in total_currency" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.payment_currency }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<v-layout py-8>
				<table class="report-table ">
					<thead>
						<tr>
							<th>Category</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in total_category" :key="index">
							<td class="item-wrapper">
								<p style="margin: 3px 0px">
									{{ item.payment_category_name }}
								</p>
							</td>
							<td>
								<p style="margin: 3px 0px">
									{{item.payment_currency}} {{ item.total.toLocaleString() }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</v-layout>
			<table class="report-table">
				<thead>
					<tr>
						<th>Account Name</th>
						<th>Total Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in groups" :key="index">
						<td class="item-wrapper">
							<p style="margin: 3px 0px">
								{{ item.payment_account_name }}
							</p>
						</td>
						<td>
							<p style="margin: 3px 0px">
								{{ item.payment_currency }} {{ item.total.toLocaleString() }}
							</p>
						</td>
					</tr>
				</tbody>
			</table>

			<v-divider class="py-4"></v-divider>

			<v-layout>
				<table class="report-table">
					<thead>
						<tr>
							<th>#</th>
							<!-- <th>
								{{$store.getters.language.data.money_move_type.money_move_type_name}}
							</th> -->
							<th>
								{{$store.getters.language.data.payments.payment_date}}
							</th>
							<th>
								Account
							</th>
							<th>
								Phone
							</th>
							<th>
								{{$store.getters.language.data.report.amount}}
							</th>
                            <th>
                                Change
                            </th>
							<th>
								Category
							</th>
							<th>
								{{$store.getters.language.data.report.note}}
							</th>
							<th>
								{{$store.getters.language.data.users.user_name}}
							</th>

							<!-- <th>
								{{$store.getters.language.data.accounts.account_name}}
							</th> -->
							<!-- <th>
								{{$store.getters.language.data.report.amount}}
							</th> -->
							<!-- <th>
								{{$store.getters.language.data.incomes.income_note}}
							</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in rows" :key="index">
                            
							<td>{{index+1}}</td>
							<!-- <td>{{item.money_move_type_name}}</td> -->
							<td>{{ new Date(item.payment_date).toISOString().split('T')[0]}} {{ new Date(item.payment_date).toISOString().split('T')[1].split('.')[0]}}</td>
							<td>{{item.payment_account_name}}</td>
							<td>{{item.payment_account_phone}}</td>
							<td>{{item.payment_currency}} {{item.payment_amount.toLocaleString()}}</td>
                            <td>
                                {{ item.payment_change_currency }}
                                {{ item.payment_change_amount }}
                            </td>
							<td>{{item.payment_category_name}}</td>
							<td>{{item.payment_note}}</td>
							<td>{{item.user_name}}</td>
						</tr>
					</tbody>
				</table>
			</v-layout>

		</v-container>
	</div>
</template>
<script>
	import requests from '../../../requests/reports.requests'
	export default {
		data() {
			return {
				rows: [],
				total: {},
				groups: [],
				total_category: [],
				total_currency: [],
				loading: false,
			}
		},
		props: {
			query: {
				type: Object,
				default: () => { }
			}
		},
		mounted() {
			this.openReport()
		},
		methods: {
			openReport() {
				this.loading = true
				let query = this.$props.query
				requests.paymentsReport(query).then(res => {
					this.rows = res.data.rows
					this.total = res.data.total
					this.total_currency = res.data.total_currency
					this.total_category = res.data.total_category
					this.groups = res.data.total_group
					this.loading = false
				})
			}
		},
	}
</script>