
<template>
	<div>
		<v-app-bar app class="navbar">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-btn icon="" to="/">
				<v-icon>mdi-home</v-icon>
			</v-btn>
			<v-app-bar-title>{{$store.getters.language.app_name}}</v-app-bar-title>
			<v-spacer></v-spacer>
			<v-btn icon class="mx-1" dark @click="$vuetify.theme.dark = !$vuetify.theme.dark">
				<v-icon>mdi-theme-light-dark</v-icon>
			</v-btn>
			<v-btn icon="" @click="logout">
				<v-icon>mdi-logout</v-icon>
			</v-btn>

			<!-- </v-app-bar>
		<v-navigation-drawer v-model="drawer" app :right="$store.getters.language.rtl">
			<template v-slot:prepend>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-center">{{$store.getters.language.app_name}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-divider class="mt-3"></v-divider>
			<v-list shaped>
				<v-list-item v-for="(item , index) in navigation_links" :key="index" :to="item.path">
					<v-list-item-content>
						<v-list-item-title>{{item.title}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer> -->
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app :right="$store.getters.language.rtl">
			<template v-slot:prepend>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-center">{{$store.getters.language.app_name}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-divider class="mt-3"></v-divider>
			<v-list shaped>

				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title>Online Shopping</v-list-item-title>
					</v-list-item-content>
				</template>

				<v-list-item to="/added_moneys-list" >
					<v-list-item-content>
						<v-list-item-title>Open Balance</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<!-- <v-list-item to="/expenses-list">
					<v-list-item-content>
						<v-list-item-title>Expenses</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
				<v-list-item to="/incomes-list">
					<v-list-item-content>
						<v-list-item-title>Incomes</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/income_accounts-list">
					<v-list-item-content>
						<v-list-item-title>Income Accounts</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/payments-list">
					<v-list-item-content>
						<v-list-item-title>Payments</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/payment_accounts-list">
					<v-list-item-content>
						<v-list-item-title>Payment Accounts</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/payment_categories-list">
					<v-list-item-content>
						<v-list-item-title>Payment Categories</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/take_outs-list" >
					<v-list-item-content>
						<v-list-item-title>Take Outs</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/reports" v-if="user.user_role==1">
					<v-list-item-content>
						<v-list-item-title>Reports</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
	export default {
		data: function () {
			return {
				drawer: null,
			}
		},
		computed: {
			languages() {
				return this.$store.getters.languages
			},
			navigation_links() {
				return [

					{
						path: '/added_moneys-list',
						title: 'Added Moneys',
					},

					{
						path: '/expenses-list',
						title: 'Expenses',
					},

					{
						path: '/incomes-list',
						title: 'Incomes',
					},

					{
						path: '/payment_accounts-list',
						title: 'Payment Accounts',
					},

					{
						path: '/payments-list',
						title: 'Payments',
					},

					// {
					// 	path: '/total_added_moneys-list',
					// 	title: 'Total Added Moneys',
					// },

					// {
					// 	path: '/users-list',
					// 	title: 'Users',
					// },
					{
						path: 'reports',
						title: 'Reports'
					}

				]
			},
			user() {
				return this.$store.getters.user
			}
		},
		methods: {
			logout() {
				this.$store.dispatch('logout');
			},
		},
	}
</script>
        