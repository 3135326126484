
import axios from 'axios';
export default {
    
    async createIncomeAccounts(params)  {
        return await axios.post(`income_accounts/create` , params)
    },
    async createIncomeAccountsList(params)  {
        return await axios.post(`income_accounts/create/list` , params)
    },
    async updateIncomeAccountsColumn(column , value , data)  {
        return await axios.put(`income_accounts/update_list?${column}=${value}` , data)
    },
    async deleteIncomeAccountsList(list)  {
        return await axios.delete(`income_accounts/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportIncomeAccounts(column , value)  {
        return await axios.get(`income_accounts/report?${column}=${value}`)
    },
    async getAllIncomeAccounts()  {
        return await axios.get(`income_accounts/all`)
    },
    async getOneIncomeAccounts(income_account_id)  {
        return await axios.get(`income_accounts/all/${income_account_id}`)
    },
    async getIncomeAccountsByColumn(column , value)  {
        return await axios.get(`income_accounts/filter?column=${column}&value=${value}`)
    },
    async deleteIncomeAccounts(income_account_id)  {
        return await axios.delete(`income_accounts/delete/${income_account_id}`)
    },
    async updateIncomeAccounts(income_account_id , params)  {
        return await axios.put(`income_accounts/update/${income_account_id}` , params)
    }
}