import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD0n5AdmEvFjCisrl2XP_Shl2Hn53R8VO0",
    authDomain: "lamsatsara-b37ab.firebaseapp.com",
    projectId: "lamsatsara-b37ab",
    storageBucket: "lamsatsara-b37ab.appspot.com",
    messagingSenderId: "974411768639",
    appId: "1:974411768639:web:5b1510b64316a18863a98a",
    measurementId: "G-HE8NY78620"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail }
