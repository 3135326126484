
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import languages from './languages.json'
import { auth } from '../firebase'
import router from '../router/index'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        languages: languages,
        language: languages[0],

        auth: {
            isAuth: false,
            user: null
        },
        init_state: false,

        added_moneys_list: [],

        expenses_list: [],

        income_accounts_list: [],
        
        incomes_list: [],

        payment_accounts_list: [],

        payments_list: [],

        payment_categories_list: [],

        total_added_moneys_list: [],

        users_list: [],

        user: {},

        take_outs_list: [],


    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,

        user: state => state.user,

        added_moneys_list: state => state.added_moneys_list,

        expenses_list: state => state.expenses_list,
    
        income_accounts_list: state => state.income_accounts_list,

        incomes_list: state => state.incomes_list,

        payment_accounts_list: state => state.payment_accounts_list,

        payments_list: state => state.payments_list,

        payment_categories_list: state => state.payment_categories_list,

        total_added_moneys_list: state => state.total_added_moneys_list,

        users_list: state => state.users_list,

        take_outs_list: state => state.take_outs_list,

        auth: state => state.auth,

    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },

        setAuth(state, payload) {
            state.auth.user = payload.user
            state.auth.isAuth = true
            localStorage.setItem('auth', JSON.stringify(auth))
        },
        setAddedMoneysList(state, added_moneys_list) {
            state.added_moneys_list = added_moneys_list
        },

        setExpensesList(state, expenses_list) {
            state.expenses_list = expenses_list
        },
        
        setIncomeAccountsList(state, income_accounts_list) {
            state.income_accounts_list = income_accounts_list
        },

        setIncomesList(state, incomes_list) {
            state.incomes_list = incomes_list
        },

        setPaymentAccountsList(state, payment_accounts_list) {
            state.payment_accounts_list = payment_accounts_list
        },

        setPaymentsList(state, payments_list) {
            state.payments_list = payments_list
        },

        setPaymentCategoriesList(state, payment_categories_list) {
            state.payment_categories_list = payment_categories_list
        },

        setTotalAddedMoneysList(state, total_added_moneys_list) {
            state.total_added_moneys_list = total_added_moneys_list
        },

        setUsersList(state, users_list) {
            state.users_list = users_list
        },
        setTakeOutsList(state, take_outs_list) {
            state.take_outs_list = take_outs_list
        },

    },
    actions: {
        init(context, uid) {
            const id = uid
            // const id = '234567890'
            axios.get('init/' + id)
                .then(r => {
                    if (r.status == 200 && r.data) {
                        context.commit('setAuth', {
                            user: r.data.user
                        })

                        context.commit('setUsersList', r.data.users)
                        context.commit('setPaymentAccountsList', r.data.payment_accounts)
                        context.commit('setTotalAddedMoneysList', r.data.total_added_moneys)
                        context.commit('setIncomeAccountsList', r.data.income_accounts)
                        context.commit('setPaymentCategoriesList', r.data.payment_categories)

                        context.state.user = r.data.user
                        context.state.init_state = true
                    } else {

                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        logout(context) {
            auth.signOut()
            localStorage.removeItem('auth')
            context.state.auth.isAuth = false
            router.push('/login')
            context.state.init_state = true
        },
    },
})
