
import axios from 'axios';
export default {
    
    async createPaymentAccounts(params)  {
        return await axios.post(`payment_accounts/create` , params)
    },
    async createPaymentAccountsList(params)  {
        return await axios.post(`payment_accounts/create/list` , params)
    },
    async updatePaymentAccountsColumn(column , value , data)  {
        return await axios.put(`payment_accounts/update_list?${column}=${value}` , data)
    },
    async deletePaymentAccountsList(list)  {
        return await axios.delete(`payment_accounts/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportPaymentAccounts(column , value)  {
        return await axios.get(`payment_accounts/report?${column}=${value}`)
    },
    async getAllPaymentAccounts()  {
        return await axios.get(`payment_accounts/all`)
    },
    async getOnePaymentAccounts(payment_account_id)  {
        return await axios.get(`payment_accounts/all/${payment_account_id}`)
    },
    async getPaymentAccountsByColumn(column , value)  {
        return await axios.get(`payment_accounts/filter?column=${column}&value=${value}`)
    },
    async deletePaymentAccounts(payment_account_id)  {
        return await axios.delete(`payment_accounts/delete/${payment_account_id}`)
    },
    async updatePaymentAccounts(payment_account_id , params)  {
        return await axios.put(`payment_accounts/update/${payment_account_id}` , params)
    }
}